import { Link } from "gatsby"
import _ from "lodash"
import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"
import below from "../../styles/media"

export const LocationName = styled.h4`
  color: ${colors.white};
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
  margin: 0;
`

export const LocationDetails = styled.p`
  color: ${colors.white};
  font-size: 13px;
  letter-spacing: 0.5px;
  max-width: 100px;
  text-align: center;
  margin: 0;
`

export const LocationWrapper = styled(props => <Link {...props} />)`
  width: 160px;
  height: 180px;
  text-decoration: none;

  transition: transform 0.4s ease;
  &:hover {
    transform: scale(0.95);
  }

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  > img {
    width: 80px;
  }

  ${below.phone`
    >*:not(${LocationName}) {
      display: none;
    }
    display: inline;
    height: auto;
    width: auto;
    margin-right: 8px;
    ${LocationName} {
      display: inline;
      &:after {
        content: "|";
        margin-left: 8px;
      }
    }
    &:last-child {
      margin-right: 0px;
      & > ${LocationName}:after {
        content: "";
        margin-left: 0px;
      }
    }
  `}
`

export const Location = ({ country, icon, city, locationLink }) => {
  return (
    <LocationWrapper to="/contact" as={locationLink ? undefined : "div"}>
      <img src={_.get(icon, "0.url")} alt={city} />
      <LocationName>{city}</LocationName>
      <LocationDetails>{country}</LocationDetails>
    </LocationWrapper>
  )
}

export default Location
