import styled, { css } from "styled-components"

import colors from "../../styles/colors"
import below from "../../styles/media"

export default styled.button`
  background: ${colors.blue};
  color: ${colors.white};
  height: 50px;
  text-align: center;
  font-weight: 800;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 16px;
  min-width: 15em;
  border: 1px solid ${colors.blue};
  cursor: pointer;
  transition: all 0.2s linear;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: ${colors.button.primary.hover};
  }

  &:disabled {
    background: ${colors.button.primary.disabled};
    border-color: ${colors.button.primary.disabled};
    color: ${colors.button.primary.disabled_font};
    cursor: not-allowed;
  }

  ${({ secondary }) =>
    secondary &&
    css`
      color: ${colors.blue};
      background: ${colors.white};
      border: 1px solid ${colors.blue};

      &:hover:not(:disabled) {
        color: ${colors.white};
        background: ${colors.blue};
      }

      &:active:not(:disabled) {
        color: ${colors.blue};
        background: ${colors.white};
      }
    `}

  ${below.tablet`
    height: 40px;
    font-size: 14px;
  `}
`
