import _ from "lodash"

import { breakText } from "../utils/text"

const getDataIncludeID = item => ({
  ..._.get(item, ["node", "data"], {}),
  id: _.get(item, "node.recordId"),
})

const trySortByWeight = item => _.get(item, "weight", 0)

export const transformToArray = (
  data,
  table,
  filter = () => true,
  sort = trySortByWeight
) =>
  _(_.get(data, [table, "edges"]))
    .map(getDataIncludeID)
    .filter(filter)
    .orderBy(sort, "desc")
    .value()

export const transformToMap = (
  data,
  table,
  key_by = "id",
  filter = () => true
) =>
  _(_.get(data, [table, "edges"]))
    .map(getDataIncludeID)
    .filter(filter)
    .keyBy(key_by)
    .value()

const transformToNameContentMap = (data, element) => {
  const records = transformToArray(data, element)
  return _.map(records, ({ name, content }) => ({ [name]: content }))
}
export const createStaticContent = (data, page_name) => {
  return _.merge(
    ...transformToNameContentMap(data, "static_content_all"),
    ...transformToNameContentMap(data, `static_content_${page_name}`)
  )
}

export const formatStaticContent = (content, name, paras = false) => {
  if (_.get(content, name) === "blank") {
    return ""
  }
  return breakText(_.get(content, name), paras)
}

export const makeStaticPageTitle = (content, title) => {
  const title_template = formatStaticContent(content, "page-title-template")
  return _.replace(title_template, "$title", title)
}

export const getPageTitle = content => {
  const title_template = formatStaticContent(content, "page-title-template")
  const page_title = formatStaticContent(content, "webpage-title")

  return _.replace(title_template, "$title", page_title)
}
