import React from "react"

export const FHWhiteIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="10 20 80 67"
    style={{ enableBackground: "new 0 0 841.89 595.28" }}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`.st0{clip-path:url(#SVGID_2_);fill:#FFFFFF;}
	.st1{fill:#FFFFFF;}`}
    </style>
    <g>
      <g>
        <defs>
          <rect
            id="SVGID_1_"
            x="13.78"
            y="24.92"
            width="72.44"
            height="58.47"
          />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
        </clipPath>
        <path
          className="st0"
          d="M78.59,66.3h-9.82V53.85h-8.73V66.3h-9.82V34.47h9.82v11.5h8.73v-11.5h9.82V66.3z M86.22,69.89V30.27
			c0-2.94-2.62-5.35-5.82-5.35H48.41c-3.2,0-5.82,2.41-5.82,5.35v53.13l9.66-8.16h28.15C83.6,75.24,86.22,72.83,86.22,69.89"
        />
      </g>
      <polygon
        className="st1"
        points="13.78,34.47 13.78,66.3 23.61,66.3 23.61,54.13 35.3,54.13 35.3,46.26 23.61,46.26 23.61,42.34 
		35.67,42.34 35.67,34.47 	"
      />
    </g>
  </svg>
)

export default FHWhiteIcon
