import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import Tick from "../Icons/Tick"
const AllWrapper = styled.div`
  margin: 0 0 4px;
`
const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    label:first-of-type {
      border-color: #96a3b8;
    }
    svg {
      opacity: 0.5;
    }
  }

  input {
    visibility: hidden;
    width: 0;
    margin: 0;

    &:checked + label {
      border-color: ${colors.white};
      > svg {
        opacity: 1;
      }
    }
  }
`
const Box = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 22px;
  width: 22px;

  border-radius: 2px;
  border: 1px solid ${colors.input.outline};
  background-color: ${colors.supportBlue};
  margin: 0 10px 0 0;

  transition: border-color 0.2s ease;

  > svg {
    transition: opacity 0.2s ease;
    opacity: 0;
    fill: ${colors.white};
    height: 17px;
  }
`
const Label = styled.label`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.23px;
  line-height: 20px;
  color: ${colors.white};
`

export const CheckBox = ({ id, label, value, onChange, name, ...rest }) => {
  return (
    <AllWrapper>
      <Wrapper>
        <input
          {...rest}
          name={name}
          id={id}
          type="checkbox"
          checked={value}
          onChange={onChange}
          readOnly
        />
        <Box htmlFor={id}>
          <Tick />
        </Box>
        <Label htmlFor={id}>{label}</Label>
      </Wrapper>
    </AllWrapper>
  )
}

export default CheckBox
