import { graphql, navigate } from "gatsby"
import _ from "lodash"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"

import { EmployeeCard } from "../components/Card/types"
import ItemRows from "../components/ItemRows"
import { PageContentsWrapper, PageLayout } from "../components/PageLayout"
import SectionTitle from "../components/SectionTitle"
import SubTitle from "../components/SectionTitle/subtitle"
import Spacer from "../components/Spacer"
import TextSections from "../components/TextSection"
import below from "../styles/media"
import {
  createStaticContent,
  getPageTitle,
  transformToArray,
} from "../data/transform"
import colors from "../styles/colors"
import { breakText } from "../utils/text"
import Slider from "infinite-react-carousel"

export const query = graphql`
  query AboutUsQuery {
    locations: allAirtable(filter: { table: { eq: "Locations" } }) {
      ...LocationsFragment
    }
    aboutUs: allAirtable(filter: { table: { eq: "AboutUs" } }) {
      edges {
        node {
          data {
            type
            title
            content
            image {
              url
            }
            weight
            show
          }
        }
      }
    }
    static_content_all: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "all" } } }
    ) {
      ...StaticContentFragment
    }
    static_content_about: allAirtable(
      filter: {
        table: { eq: "StaticContent" }
        data: { page: { eq: "about" } }
      }
    ) {
      ...StaticContentFragment
    }
  }
`

const default_main_content = `In 2014, founder Andrew Cronyn recognised there was an opportunity
to bring the immersive Fantasy experience to Australia. Since then
we have diversified our product suite to include everything from
head to head predictors & bracket challenges to match centres,
subscriptions, polling and trivia, allowing fans to continue their
conversation with like minded people.

We have extended our reach to partner with the biggest sports
leagues around the world including the NBA, NFL, MLS, AFL and NRL,
media publishers including NewsUK, Telstra, Sevenwest Media, Fox
Sports and Turner and betting providers including SunBets, BetEasy,
SportsBet and Ladbrokes amongst others.

Our 45 staff operating globally across 3 time zones, with our
headquarters in Melbourne, understand every sport and client is
unique. We want our partnership with you to be driven by your
broader strategy, while at the same time achieving technical
economies of scale by using the FanHub Platform.`

const AwardImg = styled.img`
  display: block !important;
  width: 250px !important;
  margin: 0 auto !important;
`

const SliderStyled = styled.div`
  ${below.phone`
        margin-top: 50px;
    `}

  .carousel-dots {
    li {
      display: inline-block;
      width: 40px;
      margin: 0 5px;

      button {
        width: 40px;

        &:before {
          content: "" !important;
          display: block;
          height: 5px;
          width: 40px;
          opacity: 0.5;
          border-radius: 2.5px;
          background-color: #d5d5d5;
        }
      }

      &.carousel-dots-active button:before {
        background-color: #59cff5;
      }
    }
  }
`

const AboutPage = props => {
  const locations = transformToArray(
    props.data,
    "locations",
    record => record.inFooter
  )

  const aboutUsContent = transformToArray(props.data, "aboutUs")

  const getFirstOfType = (type, default_value = undefined) =>
    _.get(
      _.filter(aboutUsContent, ({ type: t, show }) => show && t === type),
      "0",
      default_value
    )

  const title = getFirstOfType("section-title")
  const offerings_subtitle = getFirstOfType("offering-subtitle")
  const offerings = _(aboutUsContent)
    .filter(({ type }) => type === "offering")
    .filter("show")
    .map(({ title, content }) => ({ title, text: content }))
    .value()

  const settings_slider = {
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    arrowsBlock: false,
    dots: true,
  }

  const awards_subtitle = getFirstOfType("award-subtitle")
  const awards = _(aboutUsContent)
    .filter(({ type }) => type === "award")
    .filter("show")
    .map(({ title, image }) => ({ title, img_url: _.get(image, "0.url") }))
    .value()

  const employees_subtitle = getFirstOfType("employee-subtitle")
  const employees = _(aboutUsContent)
    .filter(({ type }) => type === "employee")
    .filter("show")
    .map(({ title: name, content: position, image }) => ({
      name,
      position,
      image: _.get(image, "0.url"),
    }))
    .value()

  const main_content_text = _.get(
    getFirstOfType("main-content"),
    "content",
    default_main_content
  )

  const main_content = breakText(main_content_text)

  const content = createStaticContent(props.data, "about")

  return (
    <PageLayout locations={locations} static_content={content}>
      <Helmet>
        <title>{getPageTitle(content)}</title>
      </Helmet>
      <PageContentsWrapper>
        <SectionTitle
          title={_.get(title, "title", "Who we are")}
          desc={_.get(
            title,
            "content",
            "We are global leaders in the delivery of digital fan engagement products."
          )}
          bg_icon="lineart3"
        />
        <Spacer height={60} />
        <Content>{main_content}</Content>
        <Spacer height="60" />
        {!_.isEmpty(offerings_subtitle) && (
          <React.Fragment>
            <SubTitle
              title={_.get(offerings_subtitle, "title", "What we offer")}
              desc={_.get(
                offerings_subtitle,
                "content",
                "We are global leaders in the delivery of digital fan engagement products."
              )}
            />
            <TextSections sections={offerings} />
          </React.Fragment>
        )}
        {!_.isEmpty(awards_subtitle) && (
          <React.Fragment>
            <Spacer height="100" />
            <SubTitle
              title={_.get(awards_subtitle, "title")}
              desc={_.get(awards_subtitle, "content")}
            />
            <SliderStyled>
              <Slider {...settings_slider}>
                {awards.map((award, key) => (
                  <AwardImg src={award.img_url} alt={award.title} key={key} />
                ))}
              </Slider>
            </SliderStyled>
          </React.Fragment>
        )}
        {!_.isEmpty(employees_subtitle) && (
          <React.Fragment>
            <Spacer height="100" />
            <SubTitle
              title={_.get(employees_subtitle, "title")}
              desc={_.get(employees_subtitle, "content")}
            />
            <ItemRows child_limit={0}>
              {_.map(employees, (emp, i) => {
                return <EmployeeCard {...emp} key={i} />
              })}
            </ItemRows>
          </React.Fragment>
        )}
      </PageContentsWrapper>
    </PageLayout>
  )
}

export default AboutPage

const Content = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.offBlack};
  padding: 0 30px;
`
