import IsEmail from "isemail"
import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"

import colors from "../../styles/colors"
import below from "../../styles/media"
import Button from "../Button"
import CheckBox from "../CheckBox"
import RecaptchaComponent from "../Recaptcha"

export class NewsletterBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      EMAIL: "",
      NAME: "",
      MMERGE2: "",
      error: null,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.emailref = React.createRef()
  }

  get can_submit() {
    return _.every(
      [this.state.EMAIL, this.state.MMERGE2, this.state.NAME],
      _.identity
    )
  }

  handleChange(field) {
    return e => this.setState({ [field]: e.target.value, error: null })
  }

  handleSubmit(e) {
    const valid = IsEmail.validate(this.state.EMAIL)

    if (!valid) {
      this.setState({ error: "email" })
      e.preventDefault()
      if (this.emailref && this.emailref.current) {
        this.emailref.current.scrollIntoView({ behavior: "smooth" })
      }
    }
  }

  render() {
    return (
      <Wrapper>
        <Title>{this.props.title}</Title>
        <Form
          action="https://fanhubmedia.us15.list-manage.com/subscribe/post?u=636c031424d7ed36dda2d3527&amp;id=2dbfdad773"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          novalidate
        >
          <InputBlock>
            <Input
              type="EMAIL"
              placeholder="Email Address*"
              value={this.state.EMAIL}
              onChange={this.handleChange("EMAIL")}
              show={true}
              name="EMAIL"
              id="mce-EMAIL"
              error={this.state.error === "email"}
            />
            <ErrorTooltip error={this.state.error === "email"}>
              {this.state.error === "email" && "Invalid email"}
            </ErrorTooltip>
            <EmailRefBlock ref={this.emailref} />
          </InputBlock>
          <ExtraFieldsSlider show={!_.isEmpty(this.state.EMAIL)}>
            <Input
              type="text"
              placeholder="Full Name*"
              value={this.state.NAME}
              onChange={this.handleChange("NAME")}
              name="NAME"
              id="mce-NAME"
            />
            <Input
              type="text"
              placeholder="Company"
              value={this.state.MMERGE2}
              onChange={this.handleChange("MMERGE2")}
              name="MMERGE2"
              id="mce-MMERGE2"
            />
            <LegalSection>
              <Title>Marketing Permissions</Title>

              <p>
                Please select all the ways you would like to hear from FanHub
                Media:
              </p>
              <CheckBox
                id="gdpr_19663"
                name="gdpr[19663]"
                onChange={() => null}
                label="Email"
              />
              <CheckBox
                id="gdpr_19667"
                name="gdpr[19667]"
                onChange={() => null}
                label="Direct Mail"
              />
              <CheckBox
                id="gdpr_19671"
                name="gdpr[19671]"
                onChange={() => null}
                label="Customized Online Advertising"
              />
              <p>
                You can unsubscribe at any time by clicking the link in the
                footer of our emails. For information about our privacy
                practices, please visit our website.
              </p>
              <p>
                We use Mailchimp as our marketing platform. By clicking below to
                subscribe, you acknowledge that your information will be
                transferred to Mailchimp for processing. Learn more about
                Mailchimp's privacy practices{" "}
                <a
                  href="https://mailchimp.com/legal/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here.
                </a>
              </p>
            </LegalSection>

            <Button
              secondary
              onClick={this.handleSubmit}
              disabled={!this.can_submit}
            >
              Subscribe
            </Button>
            <RecaptchaComponent />
          </ExtraFieldsSlider>
        </Form>
      </Wrapper>
    )
  }
}

export default NewsletterBar

const InputBlock = styled.div`
  position: relative;
`

const EmailRefBlock = styled.div`
  opacity: 0;
  height: 0;
  width: 0;

  position: absolute;
  top: -154px;
  z-index: -1;
`

const ErrorTooltip = styled.div`
  position: absolute;
  left: 100%;
  top: 50%;
  opacity: 0;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  padding: 0 8px;
  background-color: #f6a2b3;
  color: #ba1234;
  width: auto;
  min-width: 150px;
  text-align: center;
  white-space: nowrap;

  border-radius: 3px;

  transform: translate3d(16px, 0%, 0);

  transition: all 0.4s linear;

  ::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate3d(-6px, -50%, 0);
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #f6a2b3;
  }

  ${below.tablet`
    left: 50%;
    top: 0%;
    transform: translate3d(-50%, 0, 0);
    ::after {
      left: 50%;
      top: 0%;
      transform: translate3d(-50%, 30px, 0);
      border-top: 6px solid #f6a2b3;
      border-bottom: 6px solid transparent;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }
  `}

  ${({ error }) =>
    error &&
    css`
      opacity: 0.9;
      transform: translate3d(16px, -50%, 0);
      ${below.tablet`
        transform: translate3d(-50%, calc(-100% - 16px), 0);
      `}
    `}
`

const Wrapper = styled.section`
  width: 100%;
  background: ${colors.darkBlue};
  padding: 52px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  ${below.tablet`
    margin: 0 30px;
    width: calc(100% - 60px);
  `}
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 420px;
`

const Title = styled.h3`
  color: white;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 22px;
  margin: 0 0 1.8em;
`

const Input = styled.input`
  display: block;
  background: ${colors.supportBlue};
  border: 0;
  text-align: center;
  color: white;
  font-size: 14px;
  height: 50px;
  min-width: 420px;

  ${below.tablet`
    min-width: 200px;
    width: 300px;
    margin: 0 auto;
  `}

  border: 1px solid ${colors.input.outline};
  border-radius: 5px;

  word-break: break-all;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &:focus {
    border: 1px solid ${colors.input.active.outline};
    outline: none;
    box-shadow: none;
  }

  ::-webkit-input-placeholder {
    color: white;
  }
  ::-moz-placeholder {
    color: white;
  }
  :-ms-input-placeholder {
    color: white;
  }
  :-moz-placeholder {
    color: white;
  }

  :disabled {
    color: ${colors.input.outline};
  }

  ${({ error, error_text = "Invalid email." }) =>
    error &&
    css`
      &:not(:disabled) {
        border: 2px solid #ba1234;

        ::after {
          opacity: 1;
        }
      }
    `}
`

const ExtraFieldsSlider = styled.div`
  max-height: 0;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  transition: max-height 0.2s ease 0.2s;

  & > * {
    margin-top: 10px;
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  ${({ show }) =>
    show &&
    css`
      max-height: 600px;
      transition: max-height 0.5s ease;
      & > * {
        opacity: 1;
        transition: opacity 0.5s ease 0.25s;
      }
    `}
`

const LegalSection = styled.div`
  color: white;
  padding-top: 30px;

  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.23px;
  line-height: 20px;

  a {
    color: ${colors.blue};
  }
`
