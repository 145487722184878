import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"

import colors from "../../styles/colors"
import below from "../../styles/media"
import TitleDesc from "./description"

export const SubtitleHeader = styled.h2`
  z-index: 1;
  color: ${colors.offBlack};
  text-align: center;
  font-weight: 600;
  font-size: 50px;

  box-sizing: border-box;
  position: relative;
  padding: 0;
  margin: 0;

  ${below.phone`
    font-size: 30px;
  `}

  ${props =>
    props.underline &&
    css`
      padding-bottom: 37px;

      ${below.phone`
        padding-bottom: 26px;
      `}

      :after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        width: 80px;
        height: 5px;

        background-color: ${colors.offBlack};
        border-radius: 3px;
      }
    `}
`

export const SubtitleWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin: 40px 0;

  ${below.tablet`
    margin: 0 20px;
  `}

  > svg {
    width: 128px;
    margin-bottom: 10px;
    z-index: 1;
  }
`

export const Subtitle = ({ title, desc, underline }) => {
  return (
    <SubtitleWrapper>
      <SubtitleHeader underline={underline}>{title}</SubtitleHeader>
      {!_.isEmpty(desc) && <TitleDesc>{desc}</TitleDesc>}
    </SubtitleWrapper>
  )
}

export default Subtitle
