import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"

const Container = styled.div`
  width: 20px;
  height: 16px;
  position: relative;
  top: 0;
  right: 0;
  z-index: 1002;
  cursor: pointer;
  color: ${colors.blue};
`

const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: currentColor;
  position: absolute;
  border-radius: 1px;
  top: 0;
  left: 0;

  &:nth-child(1) {
    top: 0;
    transition: top 0.2s linear 0.2s, transform 0.2s linear 0s;
    ${props =>
      props.navOpen &&
      `
      top: 50%;
      margin-top: -1px;
      transform: rotate(45deg);
      transition: top 0.2s linear, transform 0.2s linear 0.2s;
    `};
  }

  &:nth-child(2) {
    top: 50%;
    transition: transform 0.2s linear 0s;
    margin-top: -1px;
    ${props =>
      props.navOpen &&
      `
      transform: rotate(-45deg);
      transition: transform 0.2s linear 0.2s;
    `};
  }

  &:nth-child(3) {
    top: 100%;
    transition: top 0.2s linear 0.2s, transform 0.2s linear 0s;
    margin-top: -1px;
    ${props =>
      props.navOpen &&
      `
      top: 50%;
      margin-top: -1px;
      transform: rotate(-45deg);
      transition: top 0.2s linear, transform 0.2s linear 0.2s;
    `};
  }
`

const Hamburger = ({ navOpen, handleClick }) => (
  <Container onClick={handleClick} navOpen={navOpen}>
    <Line navOpen={navOpen} />
    <Line navOpen={navOpen} />
    <Line navOpen={navOpen} />
  </Container>
)

export default Hamburger
