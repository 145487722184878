import { Link } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"

import colors from "../../styles/colors"
import below, { above, screen_sizes } from "../../styles/media"
import Hamburger from "../Hamburger"
import FanhubLogoSmall from "../Icons/fanhub/icon/blue"
import FanhubGenius from '../../images/Fanhub.png';

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = { mob_open: false }

    this.isActive = this.isActive.bind(this)
  }
  isActive(path) {
    if (typeof window !== "undefined") {
      const actual_path = window.location.pathname

      if (path === "/") {
        return actual_path === "/"
      }

      const actual_path_modified = '/' + actual_path.split("").filter((c) => {
        return c !== "/";
      }).join("");

      return actual_path_modified === path
    }
    return false
  }
  render() {
    const { scrolled } = this.props
    const show_small = scrolled && window.innerWidth > screen_sizes.tablet
    return (
      <Wrapper scrolled={scrolled}>
        <Top scrolled={scrolled}>
          <HamburgerWrapper
            onClick={() => {
              // console.log("clicked", this.state)
              this.setState(state => ({ mob_open: !state.mob_open }))
            }}
          >
            <Hamburger navOpen={this.state.mob_open} />
          </HamburgerWrapper>
          <LogoWrapper
            showsmall={show_small ? "yes" : undefined}
            scrolled={scrolled}
          >
            <Link to="/">
							{show_small ? 
								<FanhubLogoSmall className="small" />
								: 
								<img src={FanhubGenius} alt="Fanhub Genius" className="large"/>
								
							}        
            </Link>
          </LogoWrapper>
        </Top>
        <Nav scrolled={scrolled} mob_open={this.state.mob_open}>
          <List>
            <Item isActive={this.isActive("/")}>
              <StyledLink to="/">Home</StyledLink>
            </Item>
            <Item isActive={this.isActive("/about")}>
              <StyledLink to="/about">About</StyledLink>
            </Item>
            {/* <Item isActive={this.isActive("/clients")}>
              <StyledLink to="/clients">Clients</StyledLink>
            </Item> */}
            <Item isActive={this.isActive("/work")}>
              <StyledLink to="/work">Work</StyledLink>
            </Item>
            <Item isActive={this.isActive("/products")}>
              <StyledLink to="/products">Products</StyledLink>
            </Item>
            <Item isActive={this.isActive("/news")}>
              <StyledLink to="/news">News</StyledLink>
            </Item>
            <Item isActive={this.isActive("/contact")}>
              <StyledLink to="/contact">Contact</StyledLink>
            </Item>
            <Item isActive={this.isActive("/work-at-fanhub")}>
              <StyledLink to="/work-at-fanhub">Work at Fanhub</StyledLink>
            </Item>
						<Item isActive={false}>
							<StyledExternalLink href='https://geniussports.com/' target="_blank">
								Genius Sports
							</StyledExternalLink>
						</Item>
            {/*<Item>*/}
            {/*  <StyledLink*/}
            {/*    as="a"*/}
            {/*    href="https://fanhub.bamboohr.com/jobs/"*/}
            {/*    target="_blank"*/}
            {/*  >*/}
            {/*    Work at Fanhub*/}
            {/*  </StyledLink>*/}
            {/*</Item>*/}
          </List>
        </Nav>
      </Wrapper>
    )
  }
}

export default Header

const navItemHeight = 60
const navItemHeightScrolled = 40

const Top = styled.div.attrs(props => ({ className: "top" }))`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${props => (props.scrolled ? '17px 0 18px' : '0' )};
  background-color: ${colors.white};

  height: ${props => (props.scrolled ? "40px" : "100px")};

  transition: all 0.3s ease;
	${below.tablet`
		height: ${props => (props.scrolled ? "40px" : "80px")};
		padding: 17px 0 18px;
	`}
`

const smallDelay = props => props.showsmall === "yes" && " 0.2s"

const LogoWrapper = styled.div`
  > a {
    > svg.small {
      width: 25px;
      height: 20px;
      position: absolute;
      transform: translate3d(-50%, -50%, 0)
        ${props => props.showsmall !== "yes" && "scale(0.6)"};
      opacity: ${props => (props.showsmall === "yes" ? "1" : "0")};

      transition: transform 0.2s ease ${smallDelay},
        opacity 0.2s ease ${smallDelay};
    }

    > img.large {
      width: 156px;
      height: 65px;
      position: absolute;

      ${below.tablet`
				width: 120px;
				height: 50px;


      ${({ scrolled, showsmall }) =>
        scrolled &&
        !showsmall &&
        css`
          transform: translate3d(-50%, -50%, 0) scale(0.6);
        `}
    `}

      ${props =>
        props.showsmall === "yes"
          ? css`
              transition: opacity 0.2s ease, transform 0.2s ease;
              opacity: 0;
              transform: translate3d(-50%, -50%, 0) scale(0.6);
            `
          : css`
              transition: opacity 0.2s ease 0.2s, transform 0.2s ease 0.2s;
              opacity: 1;
              transform: translate3d(-50%, -50%, 0);
            `};
    }
  }
`

const Nav = styled.nav.attrs(props => ({ className: "nav" }))`
  width: 100%;
  height: ${navItemHeight}px;
  background: ${colors.darkBlue};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
  ${below.tablet`
    display: ${props => (props.mob_open ? "flex" : "none")};
    position: fixed;
    top: ${props => (props.scrolled ? "40px" : "83px")};
    background: ${colors.white};
    height: 100%;
    width: 100%;
    align-items: flex-start;
    & > ul {
      flex-direction: column;
      height: 100%;
      width: 90%;
      margin: 0 auto;
      align-items: flex-start;
      justify-content: flex-start;
      > li {
        background-color: ${colors.white}!important;
        margin-left: 0px;
        & > a {
          color: ${colors.darkBlue};
        }
      }
    }
  `}
`

const List = styled.ul.attrs(props => ({ className: "list" }))`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
  height: ${navItemHeight}px;

  transition: all 0.4s ease;
`

const Item = styled.li.attrs(props => ({
  className:
    "item " +
    (props.current
      ? "hvr-sweep-to-top"
      : "hvr-underline-reveal hvr-sweep-to-top"),
}))`
  height: ${navItemHeight}px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 900;
  margin: 0 10px;
  transition: all 0.4s ease !important;
  &:hover {
    background: ${props => (props.isActive ? colors.blue : colors.supportBlue)};
  }

  &:active {
    background: ${colors.blue};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${colors.blue};

      &:hover {
      }
    `}

  ${({ current }) =>
    current &&
    css`
      background: ${colors.blue};
    `}

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

const StyledLink = styled(Link).attrs(props => ({ className: "link" }))`
  color: inherit;
  text-decoration: none;
  display: block;
  height: ${navItemHeight}px;
  padding: 0 10px;
  line-height: ${navItemHeight}px;
  letter-spacing: 0.5px;
  font-weight: 700;

  transition: all 0.4s ease;
`

const StyledExternalLink = styled.a.attrs(props => ({ className: "link" }))`
 	color: inherit;
  text-decoration: none;
  display: block;
  height: ${navItemHeight}px;
  padding: 0 10px;
  line-height: ${navItemHeight}px;
  letter-spacing: 0.5px;
  font-weight: 700;

  transition: all 0.4s ease;
`;

const HamburgerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 13px;
  transform: translateY(-50%);
  display: none;
  ${below.tablet`
    display: block;
  `}
  padding: 0 20px;
`

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 0;
  border-bottom: 2px solid ${colors.blue};

  transition: all 0.4s ease;

  ${props =>
    props.scrolled &&
    above.tablet`
      a.link {
        height: ${navItemHeightScrolled}px;
        line-height: ${navItemHeightScrolled}px;
      }
      .nav {
        height: ${navItemHeightScrolled}px;
      }

      .list {
        height: ${navItemHeightScrolled}px;
      }

     .item {
        height: ${navItemHeightScrolled}px;
      }

      .top {
        padding: 10px 0;
      }
    `}
`
