import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ post }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          baseUrl
          author
        }
      }
    }
  `)

  let { description, baseUrl, author, title } = data.site.siteMetadata
  const image = null

  if (baseUrl === "" && typeof window !== "undefined") {
    baseUrl = window.location.origin
  }

  if (baseUrl === "") {
    console.error("Please set a baseUrl in your site metadata!")
    return null
  }

  const url = new URL(baseUrl)

  const schemaOrg = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url,
    description,
    name: title,
    author: {
      "@type": "Person",
      name: author,
    },
  }

  return (
    <Helmet>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      {image && <meta name="image" content={image} />}

      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}

      <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
    </Helmet>
  )
}

export default SEO
