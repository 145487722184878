import _ from "lodash"
import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"
import below from "../../styles/media"

const SectionWrapper = styled.div`
  flex: 0 0 calc(33% - 40px);
  min-width: 250px;

  margin: 0 0 20px 40px;

  ${below.tablet`
    padding: 0 20px;
    flex: 0 0 calc(50% - 40px);
  `}

  ${below.tablet`
    flex: 1 0 0;
  `}
`

const Text = styled.p`
  margin: 0;
  color: ${colors.offBlack};
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.5px;
  font-weight: 300;
`

const Sections = styled.div`
  margin: 0 0 -20px -40px;
  display: flex;
  flex-wrap: wrap;
`

export const TextSection = ({ text }) => {
  return (
    <SectionWrapper>
      <Text>{text}</Text>
    </SectionWrapper>
  )
}

export const TextSections = ({ sections }) => (
  <Sections>
    {_.map(sections, (section, i) => (
      <TextSection {...section} key={i} />
    ))}
  </Sections>
)

export default TextSections
