import React from "react"
import { ReCaptcha } from "react-recaptcha-google"
import styled from "styled-components"

export const ReCaptchaWrapper = styled.div``

export class RecaptchaComponent extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
  }
  componentDidMount() {
    if (this.captchaEl) {
      // console.log("started, just a second...")
      this.captchaEl.reset()
    }
  }
  onLoadRecaptcha() {
    if (this.captchaEl) {
      // console.log("loaded?")
      this.captchaEl.reset()
    }
  }
  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
    // console.log(recaptchaToken, "<= your recaptcha token")
  }

  render() {
    return (
      <ReCaptchaWrapper>
        <ReCaptcha
          ref={el => {
            this.captchaEl = el
          }}
          size="normal"
          data-theme="light"
          render="explicit"
          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
          onloadCallback={this.onLoadRecaptcha}
          verifyCallback={this.verifyCallback}
        />
      </ReCaptchaWrapper>
    )
  }
}

export default RecaptchaComponent
