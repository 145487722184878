import _ from "lodash"
import React from "react"

export const breakText = (text, paras = true) => {
  if (paras) {
    return _(text)
      .split(/[\n\r]+/g)
      .map((t, i) => <p key={i}>{t}</p>)
      .value()
  }
  const nodes = _(text)
    .split(/[\n\r]+/g)
    .flatMap((value, index, array) =>
      array.length - 1 !== index // check for the last item
        ? [value, <br key={index} />]
        : value
    )
    .value()

  // If there's only 1 line, make sure you just return that string
  // not a list of strings - it might be an empty string
  // If in a list, you cannot test against empty strings
  if (_.size(nodes) === 1) {
    return _.get(nodes, 0)
  }

  return nodes
}
