import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"
import below from "../../styles/media"
import Footer from "../Footer"
import GlobalStyle from "../globalStyles"
import Header from "../Header"
import { withScrolledWatcher } from "../Header/scroll"
import SEO from "../SEO"
import Spacer from "../Spacer"

const PageLayoutWrapper = styled.div`
  padding-top: 100px;
  background-color: ${props => props.bg};

  ${below.tablet`
    padding-top: 80px;
  `}
`

export const PageContentsWrapper = styled.div`
  margin: 0 auto;
  max-width: 1080px;
`

export const PageLayout = ({
  children,
  hero,
  bg = colors.lightGrey,
  locations,
  bottom_child,
  static_content,
  locationLink = true,
}) => {
  const ScrollHeader = withScrolledWatcher(Header)
  return (
    <>
      <GlobalStyle includeFontFamily />
      <SEO />

      <PageLayoutWrapper hasHero={Boolean(hero)} bg={bg}>
        <ScrollHeader
          ignore_scroll_up={false}
          scroll_up_threshold={30}
          scroll_down_threshold={50}
        />
        {hero && hero}
        {children}
        <Spacer height={150} />
        {bottom_child && bottom_child}
        <Footer
          locations={locations}
          handleSubscribe={() => null}
          static_content={static_content}
          locationLink={locationLink}
        />
      </PageLayoutWrapper>
    </>
  )
}

export default PageLayout
