import _ from "lodash"
import React from "react"
import styled from "styled-components"

import { formatStaticContent } from "../../data/transform"
import colors from "../../styles/colors"
import below from "../../styles/media"
import FHWhiteIcon from "../Icons/fanhub/icon/white"
import LinkedInLogo from "../Icons/linkedin"
import Location from "../Location"
import NewsletterBar from "../NewsletterBar"
import FanHubPrivacyPolicyJulyFile from "../../assets/FanHubPrivacyPolicyJuly2020.pdf";

const BottomPanel = styled.div`
  padding: 19px 15px 21px;
  border-top: 1px solid ${colors.blue};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${below.phone`
    padding: 15px 2px 16px;

  `}
`
const BottomSection = styled.div`
  width: fit-content;
  margin-top: 4px;
  font-size: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 600;
  user-select: none;
  align-self: center;
  padding: 1px;
  text-align: center;
  ${below.phone`
    font-size: 8px;
    letter-spacing: 0px;
  `}

  > svg {
    height: 20px;
    margin: 0 10px;
    transform: translateY(25%);

    ${below.phone`
      height: 16px;
      margin: 0 0px;
      margin-bottom: 2px;
      align-self: center;
    `}
  }

  &:first-child > svg {
    margin-left: 0;
    ${below.phone`
      margin-bottom: 2px;
    `}
  }
  &:last-child > svg {
    margin-right: 0;
  }

  &.linkedIn {
    color: ${colors.white};
    text-decoration: none;
  }
  ${below.phone`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: flex-start;
  `}
`
const LocationPanelWrapper = styled.div`
  padding-right: 20px;
`
const LocationPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  padding: 55px 0;

  margin-right: -20px;

  > div {
    margin-right: 20px;
  }

  ${below.phone`
    flex-wrap: wrap;
    > div {
      margin-right: 8px;
    }
  `}
`
const SubscriptionPanel = styled.div`
  background-color: ${colors.darkBlue};
  min-height: 100px;
`

const GamblingContent = styled.div`
  text-align: center;
  margin-top: 13px;
  ${below.phone`
    width: 70%;
    align-self: center;
  `}

`;

const GamblingLink = styled.a`
  font-size: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 600;
  user-select: none;
  text-decoration: underline;
  color: #FFFFFF;
  margin-left: 3px;
  cursor: pointer;
  ${below.phone`
    font-size: 8px;
    letter-spacing: 0px;
    line-height: normal;
  `}
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  color: ${colors.white};
  background-color: ${colors.supportBlue};
`

export const PrivacyPolicyFile = styled.a`
  margin-left: 10px;
  color: #FFFFFF;
  text-decoration: none;
  ${below.phone`
    margin-left: 0px;
    align-self: center;
  `}
`

export const Footer = ({
  locations,
  handleSubscribe,
  static_content,
  locationLink = true,
}) => {
  return (
    <FooterWrapper>
      <SubscriptionPanel>
        <NewsletterBar
          title={formatStaticContent(static_content, "newsletter-title")}
        />
      </SubscriptionPanel>
      <LocationPanelWrapper>
        <LocationPanel>
          {_.map(locations, (location, i) => (
            <Location key={i} {...location} locationLink={locationLink} />
          ))}
        </LocationPanel>
      </LocationPanelWrapper>
      <BottomPanel>
        <BottomSection>
          <FHWhiteIcon />
          {formatStaticContent(static_content, "copyright-notice")}
          <PrivacyPolicyFile href={FanHubPrivacyPolicyJulyFile} target={'_blank'}>Privacy Policy</PrivacyPolicyFile>
        </BottomSection>
        <BottomSection>
          <GamblingContent>
            Fan Hub Media UK Limited is licensed and regulated in Great Britain by the Gambling Commission under account number
            <GamblingLink href={"https://registers.gamblingcommission.gov.uk/45334"}>45334</GamblingLink>
          </GamblingContent>

        </BottomSection>
        <BottomSection
          className="linkedIn reduceHover"
          as="a"
          href="https://www.linkedin.com/company/fanhubmedia/"
          target="_blank"
        >
          {formatStaticContent(static_content, "linked-in-text")}
          <LinkedInLogo />
        </BottomSection>
      </BottomPanel>
    </FooterWrapper>
  )
}

export default Footer
