import React from "react"
import _ from "lodash"

export const withScrolledWatcher = WrappedComponent => {
  return class WithScrolledWatcherComponent extends React.Component {
    static defaultProps = {
      ignore_scroll_up: false,
      scroll_up_threshold: 30,
      scroll_down_threshold: 50,
    }
    constructor(props) {
      super(props)

      this.handleScroll = this.handleScroll.bind(this)

      this.state = {
        bodyScrollTop: 0,
        directionUp: false,
        last: 0,
      }
    }

    componentDidMount() {
      document.addEventListener("scroll", this.handleScroll)
    }

    componentWillUnmount() {
      document.removeEventListener("scroll", this.handleScroll)
    }

    handleScroll(e) {
      // Scroll element should be one of HTML, Body or #root
      if (
        _.toLower(document.scrollingElement.tagName) !== "html" &&
        _.toLower(document.scrollingElement.tagName) !== "body" &&
        _.toLower(document.scrollingElement.id) !== "root"
      ) {
        return
      }

      this.setState(state => {
        const top = document.scrollingElement.scrollTop
        let directionUp = state.directionUp
        let last = state.last

        if (Math.abs(top - state.last) > this.props.scroll_up_threshold) {
          last = top
          directionUp = top - state.last < 0
        }

        return {
          bodyScrollTop: top,
          last,
          directionUp,
        }
      })
    }

    get scrolled() {
      const { ignore_scroll_up } = this.props
      if (this.state.directionUp && !ignore_scroll_up) {
        return false
      }
      return this.state.bodyScrollTop > this.props.scroll_down_threshold
    }

    render() {
      const { ignore_scroll_up, ...rest } = this.props
      return <WrappedComponent scrolled={this.scrolled} {...rest} />
    }
  }
}
